import React, { useEffect } from "react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";

import DX7 from "../images/1.png";
import Terios from "../images/2.jpg";
import Tucson from "../images/3.png";
import Tucson12 from "../images/4.png";

import Sozuki_Grand from '../images/Suzuki_Grand_Vitara.jpg';
import Kia from '../images/Kia_Rio.jpg';
import SanYong from '../images/SangYong.jpg';
import Tucson_2016 from '../images/Tucson_2016.png';
import Tucson_2012 from '../images/Tucson_2012.jpg';
import Tucson_2010 from '../images/Tucson_2010.jpg';
import Tucson_2014 from '../images/tucson2014.png';


import { Link } from "react-router-dom";
import { IconCar, IconPhone, IconStar } from "@tabler/icons-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

function Models() {

  const navigate = useNavigate();

  const carModels = [
    {
      name: "Daihatsu Terios",
      image: Terios,
      stars: 5,
      details: [
        { feature: "Daihatsu", icon: <IconCar /> },
        { feature: "4/4", icon: <IconCar /> },
        { feature: "Manual", icon: <IconCar /> },
        { feature: "Gasoline", icon: <IconCar /> }
      ],
      link: "/"
    },
    {
      name: "Hyundai Tucson GL 2016",
      image: Tucson_2016,
      stars: 5,
      details: [
        { feature: "Hiunday", icon: <IconCar /> },
        { feature: "4x4", icon: <IconCar /> },
        { feature: "Auto", icon: <IconCar /> },
        { feature: "Gasoline", icon: <IconCar /> }
      ],
      link: "/"
    },
    {
      name: "Hyundai Tucson GL 2014",
      image: Tucson_2014,
      stars: 5,
      details: [
        { feature: "Hiunday", icon: <IconCar /> },
        { feature: "4x4", icon: <IconCar /> },
        { feature: "Auto", icon: <IconCar /> },
        { feature: "Diesel", icon: <IconCar /> }
      ],
      link: "/"
    },
    {
      name: "Hyundai Tucson GL 2012",
      image: Tucson_2012,
      stars: 5,
      details: [
        { feature: "Hiunday", icon: <IconCar /> },
        { feature: "4x4", icon: <IconCar /> },
        { feature: "Auto", icon: <IconCar /> },
        { feature: "Diesel", icon: <IconCar /> }
      ],
      link: "/"
    },
    {
      name: "Hyundai Tucson GL 2010",
      image: Tucson_2010,
      stars: 5,
      details: [
        { feature: "Hiunday", icon: <IconCar /> },
        { feature: "4x2", icon: <IconCar /> },
        { feature: "Auto", icon: <IconCar /> },
        { feature: "Diesel", icon: <IconCar /> }
      ],
      link: "/"
    },
    {
      name: "Sangyong Korando 2014",
      image: SanYong,
      stars: 5,
      details: [
        { feature: "Korando", icon: <IconCar /> },
        { feature: "4x4", icon: <IconCar /> },
        { feature: "Auto", icon: <IconCar /> },
        { feature: "Diesel", icon: <IconCar /> }
      ],
      link: "/"
    },
    {
      name: "Kia Rio Hatchback 2014",
      image: Kia,
      stars: 5,
      details: [
        { feature: "Rio", icon: <IconCar /> },
        { feature: "4x2", icon: <IconCar /> },
        { feature: "Manual", icon: <IconCar /> },
        { feature: "Gasoline", icon: <IconCar /> }
      ],
      link: "/"
    },
    {
      name: "Suzuki Grand Vitara 2016",
      image: Sozuki_Grand,
      stars: 5,
      details: [
        { feature: "Grand Vitara", icon: <IconCar /> },
        { feature: "4x2", icon: <IconCar /> },
        { feature: "Auto", icon: <IconCar /> },
        { feature: "Gasoline", icon: <IconCar /> }
      ],
      link: "/"
    }
  ];

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/71987667', '_blank'); // Abre en una nueva pestaña
  };


  useEffect(() => {
    if (window.location.pathname !== '/models') {
      navigate('/models');
    }
  }, [navigate]);

  return (
    <>
      <button className="btn-wsp" onClick={handleWhatsAppClick}>
        <FontAwesomeIcon icon={faWhatsapp} />
      </button>
      <section className="models-section">
        <HeroPages name="Vehicle Models" />
        <div className="container">
          <div className="models-div">
            {carModels.map(car => (
              <div className="models-div__box" key={car.name}>
                <div className="models-div__box__img">
                  <img src={car.image} alt={`${car.name} image`} />
                </div>
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>{car.name}</p>
                      <p>OR SIMILAR</p>
                      <span>
                        {Array(car.stars).fill().map((_, i) => (
                          <IconStar key={i} width={15} height={15} />
                        ))}
                      </span>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    {car.details.map((detail, index) => (
                      <span key={index}>
                        {detail.icon} {detail.feature}
                      </span>
                    ))}
                  </div>
                  <div className="models-div__box__descr__name-price__btn">
                    <Link to={car.link} onClick={() => window.scrollTo(0, 0)}>
                      Get a Quote
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <IconPhone width={40} height={40} />
                <h3>+1 (646) 980-2764</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Models;
