import { useEffect, useState } from "react";
import { Resend } from 'resend';
import { useCar } from './CarContext'; // Importa el hook

import DX7 from "../images/1.png";
import Terios from "../images/2.jpg";
import Tucson from "../images/3.png";
import Tucson12 from "../images/4.png";

import Sozuki_Grand from '../images/Suzuki_Grand_Vitara.jpg';
import Kia from '../images/Kia_Rio.jpg';
import SanYong from '../images/SangYong.jpg';
import Tucson_2016 from '../images/tucson2014.png';
import Tucson_2012 from '../images/Tucson_2012.jpg';
import Tucson_2010 from '../images/Tucson_2010.jpg';
import Tucson_2014 from '../images/Tucson_2014.jpg';


import { IconCar, IconInfoCircleFilled, IconX } from "@tabler/icons-react";
import { IconMapPinFilled } from "@tabler/icons-react";
import { IconCalendarEvent } from "@tabler/icons-react";



const resend = new Resend('re_6Q92rmc2_3KqtcNEe6FdYLCTfaEKncU1q');

function BookCar() {

  const [modal, setModal] = useState(false); //  class - active-modal

  // booking car
  const { carType, handleCarChange } = useCar();
  console.log("Current car type in BookCar:", carType);
  //const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");

  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [passport, setPassport] = useState("");
  const [email, setEmail] = useState("");
  const [DriverIssuance, setDriverIssuance] = useState("");
  const [ArrivalFlight, setArrivalFlight] = useState("");
  const [DeparturaFlight, setDeparturaFlight] = useState("");
  const [DriverLicense, setDriverLicense] = useState("");
  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handlePassport = (e) => {
    setPassport(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleDriverLicense = (e) => {
    setDriverLicense(e.target.value);
  };

  const handleDriverIssuance = (e) => {
    setDriverIssuance(e.target.value);
  };

  const handleArrivalFlight = (e) => {
    setArrivalFlight(e.target.value);
  };

  const handleDeparturaFlight = (e) => {
    setDeparturaFlight(e.target.value);
  };

  //Secondly Conductor
  // modal infos
  const [name2, setName2] = useState("");
  const [lastName2, setLastName2] = useState("");
  const [phone2, setPhone2] = useState("");
  const [passport2, setPassport2] = useState("");
  const [email2, setEmail2] = useState("");
  const [DriverIssuance2, setDriverIssuance2] = useState("");
  const [ArrivalFlight2, setArrivalFlight2] = useState("");
  const [DeparturaFlight2, setDeparturaFlight2] = useState("");
  const [DriverLicense2, setDriverLicense2] = useState("");

  // taking value of modal inputs for Secondly Conductor
  const handleName2 = (e) => {
    setName2(e.target.value);
  };

  const handleLastName2 = (e) => {
    setLastName2(e.target.value);
  };

  const handlePhone2 = (e) => {
    setPhone2(e.target.value);
  };

  const handlePassport2 = (e) => {
    setPassport2(e.target.value);
  };

  const handleEmail2 = (e) => {
    setEmail2(e.target.value);
  };

  const handleDriverLicense2 = (e) => {
    setDriverLicense2(e.target.value);
  };

  const handleDriverIssuance2 = (e) => {
    setDriverIssuance2(e.target.value);
  };

  const handleArrivalFlight2 = (e) => {
    setArrivalFlight2(e.target.value);
  };

  const handleDeparturaFlight2 = (e) => {
    setDeparturaFlight2(e.target.value);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickTime === "" ||
      dropTime === "" ||
      carType === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking
  const confirmBooking = async (e) => {
    e.preventDefault();
    setModal(!modal);
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "flex";
    sendDataToWeb3Forms();
    //await sendConfirmationEmail();

    setTimeout(() => {
      window.location.reload();
    }, 8000);
  };

  // taking value of booking inputs
  // const handleCar = (e) => {
  //   setCarType(e.target.value);
  //   setCarImg(e.target.value);
  // };

  const handleCar = (e) => {
    handleCarChange(e.target.value); // Actualiza el carType en el contexto
    setCarImg(e.target.value); // Actualiza la imagen basada en el carType seleccionado
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  // based on value name show car img
  let imgUrl;
  switch (carImg) {
    case "Daihatsu Terios Bego":
      imgUrl = Terios;
      break;
    case "Hyundai Tucson GL 2016":
      imgUrl = Tucson_2014;
      break;
    case "Sangyong Korando 2014":
      imgUrl = SanYong;
      break;
    case "Hyundai Tucson GL 2012":
      imgUrl = Tucson_2012;
      break;
    case "Hyundai Tucson GL 2010":
      imgUrl = Tucson_2010;
      break;
    case "Hyundai Tucson GL 2012":
      imgUrl = Tucson_2012;
      break;
    case "Hyundai Tucson GL 2014":
      imgUrl = Tucson_2016;
      break;
    case "Kia Rio Hatchback 2014":
      imgUrl = Kia;
      break;
    case "Suzuki Grand Vitara":
      imgUrl = Sozuki_Grand;
      break;
    default:
      imgUrl = "";
  }

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  // Estado para almacenar la opción seleccionada de "Receive Recommendations"
  const [receiveRecommendations, setReceiveRecommendations] = useState("");

  // Función para manejar el cambio en la selección de recomendaciones
  const handleRecommendationsChange = (e) => {
    setReceiveRecommendations(e.target.value);
  };


  const sendDataToWeb3Forms = async () => {
    // Construir cadenas de texto legibles para los datos
    const locationAndDate = `Pick-Up Date & Time: ${pickTime},\nDrop-Off Date & Time: ${dropTime},\nPick-Up Location: ${pickUp},\nDrop-Off Location: ${dropOff}`;

    const personalInformation = `First Name: ${name},\nLast Name: ${lastName},\nPhone Number: ${phone},\nEmail: ${email}, \nPassport: ${passport}, \nDriver's License Issuance Date: ${DriverIssuance}, \nDriver's license number: ${DriverLicense}, \nArrival flight number: ${ArrivalFlight}, \nDeparture flight number: ${DeparturaFlight}`;

    const optionalCoverages = `Deductible Protection: ${document.getElementById('deductibleProtection').checked ? "Yes" : "No"},
  Tire Protection: ${document.getElementById('tireProtection').checked ? "Yes" : "No"},
  Glass Protection: ${document.getElementById('glassProtection').checked ? "Yes" : "No"},
  No Coverage Needed: ${document.getElementById('noCoverageNeeded').checked ? "Yes" : "No"},
  Other: ${document.getElementById('otherCoverageInput').value}`;

    const optionalExtras = `Portable Internet: ${document.getElementById('portableInternet').checked ? "Yes" : "No"},
  Child Seat: ${document.getElementById('childSeat').checked ? "Yes" : "No"},
  Bike Rack: ${document.getElementById('bikeRack').checked ? "Yes" : "No"},
  Roof Bars: ${document.getElementById('roofBars').checked ? "Yes" : "No"},
  No Extras Needed: ${document.getElementById('noExtrasNeeded').checked ? "Yes" : "No"},
  Other: ${document.getElementById('otheroptional-extrasInput').value}`;

    const selectedCar = `Selected car: ${carType}`;

    const SecondlyConductor = `First Name: ${name2},\nLast Name: ${lastName2},\nPhone Number: ${phone2},\nEmail: ${email2}, \nPassport: ${passport2}, \nDriver's License Issuance Date: ${DriverIssuance2}, \nDriver's license number: ${DriverLicense2}`;
    // Construir el objeto formData
    const formData = {
      "Location & Date": locationAndDate,
      "Personal Information": personalInformation,
      "Optional Coverages": optionalCoverages,
      "Optional Extras": optionalExtras,
      "Receive Recommendations": receiveRecommendations,
      "Selected Car": selectedCar,
      "Secondary Driver": SecondlyConductor
    };

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          apikey: '06814229-c6e1-4391-ad93-62920907b09f',
          ...formData
        })
      });

      if (!response.ok) {
        console.log('Error al enviar los datos.');
      }

      const responseData = await response.json();
      console.log('Datos enviados exitosamente:', responseData);
    } catch (error) {
      console.error('Hubo un error:', error);
    }
  };

  return (
    <>
      <section id="booking-section" className="book-section">
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>Book a car</h2>

              <p className="error-message">
                All fields required! <IconX width={20} height={20} />
              </p>

              <p className="booking-done">
                Your request has been sent; we will be in touch.{" "}
                <IconX width={20} height={20} onClick={hideMessage} />
              </p>

              <form className="box-form">
                <div className="box-form__car-type">
                  <label>
                    <IconCar className="input-icon" /> &nbsp; Select Your Car
                    Type <b>*</b>
                  </label>
                  <select value={carType} onChange={(e) => handleCarChange(e.target.value)}>
                    <option>Select your car type</option>
                    <option value="Daihatsu Terios Bego">Daihatsu Terios Bego Or Similar</option>
                    <option value="Hyundai Tucson GL 2016">Hyundai Tucson GL 2016 Or Similar</option>
                    <option value="Sangyong Korando 2014">Sangyong Korando 2014 Or Similar</option>
                    <option value="Hyundai Tucson GL 2012">Hyundai Tucson GL 2012 Or Similar</option>
                    <option value="Hyundai Tucson GL 2010">Hyundai Tucson GL 2010 Or Similar</option>
                    <option value="Hyundai Tucson GL 2012">Hyundai Tucson GL 2012 Or Similar</option>
                    <option value="Hyundai Tucson GL 2014">Hyundai Tucson GL 2014 Or Similar</option>
                    <option value="Kia Rio Hatchback 2014">Kia Rio Hatchback 2014 Or Similar</option>
                    <option value="Suzuki Grand Vitara">Suzuki Grand Vitara Or Similar</option>
                  </select>
                </div>

                <div className="box-form__car-type">
                  <label>
                    <IconMapPinFilled className="input-icon" /> &nbsp; Pick-up{" "}
                    <b>*</b>
                  </label>
                  <select value={pickUp} onChange={handlePick}>
                    <option>Select pick up location</option>
                    <option>SJO Airport</option>
                    <option>Liberia Airport</option>
                    <option>Hotel</option>
                    <option>Residence</option>
                    <option>Other Place</option>
                  </select>
                </div>

                <div className="box-form__car-type">
                  <label>
                    <IconMapPinFilled className="input-icon" /> &nbsp; Drop-of{" "}
                    <b>*</b>
                  </label>
                  <select value={dropOff} onChange={handleDrop}>
                    <option>Select drop off location</option>
                    <option>SJO Airport</option>
                    <option>Liberia Airport</option>
                    <option>Hotel</option>
                    <option>Residence</option>
                    <option>Other Place</option>
                  </select>
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="picktime">
                    <IconCalendarEvent className="input-icon" /> &nbsp; Pick-up{" "}
                    <b>*</b>
                  </label>
                  <input
                    id="picktime"
                    value={pickTime}
                    onChange={handlePickTime}
                    type="date"
                  ></input>
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="droptime">
                    <IconCalendarEvent className="input-icon" /> &nbsp; Drop-of{" "}
                    <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={dropTime}
                    onChange={handleDropTime}
                    type="date"
                  ></input>
                </div>

                <input type="hidden" name="ccemail" value="felipealvarez1224@gmail.com" />

                <button onClick={openModal} type="submit">
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}

      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div className="booking-modal__title">
          <h2>Complete Reservation</h2>
          <IconX onClick={openModal} />
        </div>
        {/* message */}
        <div className="booking-modal__message">
          <h4>
            <IconInfoCircleFilled /> Upon completing this reservation enquiry,
            you will receive:
          </h4>
          <p>
            Your rental voucher to produce on arrival at the rental desk and a
            toll-free customer support number.
          </p>
        </div>
        {/* car info */}
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} /{" "}
                    <input type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <IconMapPinFilled />
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> {carType}
            </h5>
            {imgUrl && <img src={imgUrl} alt="car_img" />}
          </div>
        </div>

        {/* personal info */}
        <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                  value={phone}
                  onChange={handlePhone}
                  type="text"
                  placeholder="Enter your phone number"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Passport <b>*</b>
                </label>
                <input
                  value={passport}
                  onChange={handlePassport}
                  type="text"
                  placeholder="Enter your passport number"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  Driver's License Issuance Date<b>*</b>
                </label>
                <input
                  value={DriverIssuance}
                  onChange={handleDriverIssuance}
                  type="text"
                  placeholder="Enter your Driver´s License Issuance Date"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Driver's license number<b>*</b>
                </label>
                <input
                  value={DriverLicense}
                  onChange={handleDriverLicense}
                  type="text"
                  placeholder="Enter your Driver's license number"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  Arrival flight number <b>*</b>
                </label>
                <input
                  value={ArrivalFlight}
                  onChange={handleArrivalFlight}
                  type="text"
                  placeholder="Enter your Arrival flight number"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Departure flight number <b>*</b>
                </label>
                <input
                  value={DeparturaFlight}
                  onChange={handleDeparturaFlight}
                  type="text"
                  placeholder="Enter your Departure flight number"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            {/* Optional Coverages */}
            <div className="booking-modal__optional-coverages">
              <h4>Optional Coverages</h4>
              <div className="option">
                <input type="checkbox" id="deductibleProtection" name="deductibleProtection" />
                <label htmlFor="deductibleProtection">Deductible Protection: $80</label>
              </div>
              <div className="option">
                <input type="checkbox" id="tireProtection" name="tireProtection" />
                <label htmlFor="tireProtection">Tire Protection: $80</label>
              </div>
              <div className="option">
                <input type="checkbox" id="glassProtection" name="glassProtection" />
                <label htmlFor="glassProtection">Glass Protection: $80</label>
              </div>
              <div className="option">
                <input type="checkbox" id="noCoverageNeeded" name="noCoverageNeeded" />
                <label htmlFor="noCoverageNeeded">I don't need coverage</label>
              </div>
              <div className="option">
                <input type="checkbox" id="otherCoverage" name="otherCoverage" />
                <label htmlFor="otherCoverage">Other:</label>
                <input type="text" id="otherCoverageInput" name="otherCoverageInput" />
              </div>
            </div>


            {/* Optional Extras */}
            <div className="booking-modal__optional-extras">
              <h4>Optional Extras</h4>
              <div className="option">
                <input type="checkbox" id="portableInternet" name="portableInternet" />
                <label htmlFor="portableInternet">Portable Internet: $40</label>
              </div>
              <div className="option">
                <input type="checkbox" id="childSeat" name="childSeat" />
                <label htmlFor="childSeat">Child Seat: $50</label>
              </div>
              <div className="option">
                <input type="checkbox" id="bikeRack" name="bikeRack" />
                <label htmlFor="bikeRack">Bike Rack: $35</label>
              </div>
              <div className="option">
                <input type="checkbox" id="roofBars" name="roofBars" />
                <label htmlFor="roofBars">Roof Bars: $35</label>
              </div>
              <div className="option">
                <input type="checkbox" id="noExtrasNeeded" name="noExtrasNeeded" />
                <label htmlFor="noExtrasNeeded">I don't need extras</label>
              </div>
              <div className="option">
                <input type="checkbox" id="otheroptional-extras" name="otheroptional-extras" />
                <label htmlFor="otheroptional-extras">Other:</label>
                <input type="text" id="otheroptional-extrasInput" name="otheroptional-extrasInput" />
              </div>
            </div>

            <div className="booking-modal__recommendations">
              <h4>Would you like to receive recommendations during the trip</h4>
              <div className="option">
                <input
                  type="radio"
                  id="recommendationsYes"
                  name="recommendations"
                  value="Yes"
                  onChange={handleRecommendationsChange} // Add onChange event
                />
                <label htmlFor="recommendationsYes">Yes</label>
              </div>
              <div className="option">
                <input
                  type="radio"
                  id="recommendationsNo"
                  name="recommendations"
                  value="No"
                  onChange={handleRecommendationsChange} // Add onChange event
                />
                <label htmlFor="recommendationsNo">No</label>
              </div>
              <div className="option">
                <input
                  type="radio"
                  id="recommendationsOther"
                  name="recommendations"
                  value="Other"
                  onChange={handleRecommendationsChange} // Add onChange event
                />
                <label htmlFor="recommendationsOther">Other</label>
              </div>
            </div>

            <h4>Additional Driver Personal Information</h4>
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b></b>
                </label>
                <input
                  value={name2}
                  onChange={handleName2}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
              </span>

              <span>
                <label>
                  Last Name <b></b>
                </label>
                <input
                  value={lastName2}
                  onChange={handleLastName2}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
              </span>

              <span>
                <label>
                  Phone Number <b></b>
                </label>
                <input
                  value={phone2}
                  onChange={handlePhone2}
                  type="text"
                  placeholder="Enter your phone number"
                ></input>
              </span>

              <span>
                <label>
                  Passport <b></b>
                </label>
                <input
                  value={passport2}
                  onChange={handlePassport2}
                  type="text"
                  placeholder="Enter your passport number"
                ></input>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b></b>
                </label>
                <input
                  value={email2}
                  onChange={handleEmail2}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  Driver's License Issuance Date<b></b>
                </label>
                <input
                  value={DriverIssuance2}
                  onChange={handleDriverIssuance2}
                  type="text"
                  placeholder="Enter your Driver´s License Issuance Date"
                ></input>
              </span>

              <span>
                <label>
                  Driver's license number<b></b>
                </label>
                <input
                  value={DriverLicense2}
                  onChange={handleDriverLicense2}
                  type="text"
                  placeholder="Enter your Driver's license number"
                ></input>
              </span>
            </div>

            <span className="info-form__checkbox">
              <input type="checkbox"></input>
              <p>Please send me latest news and updates</p>
            </span>

            <div className="reserve-button">
              <button onClick={confirmBooking}>Get a Quote</button>
            </div>

          </form>
        </div>
      </div>
    </>
  );
}

export default BookCar;
