import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">

            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Frequently Asked Questions</h2>
              <p>
                Frequently Asked Questions About the Car Rental Booking Process
                on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions">

              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question ${getClassQuestion("q1")}`}
                >
                  <p>1. What are the accepted methods of payment for the rental service?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  For your convenience, we accept both PayPal and cash payments. This gives you flexibility in how you wish to pay for our rental services. If you prefer to pay securely and quickly electronically, PayPal is an excellent option. On the other hand, if you prefer to pay in cash when you pick up the vehicle, we are also prepared to assist you. Our goal is to provide you with payment options that suit your individual preferences and needs.
                </div>
              </div>

              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. What coverages are included in the basic insurance and what are the options to add additional coverages?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                 Our base rate includes standard insurance for your peace of mind and security during the rental period. However, we understand that every traveler has different needs, so we also offer the possibility to add extra coverages to your insurance. These additional coverages can include collision damage waiver, theft, extended liability, among others. We are here to help you customize your insurance according to your specific needs and preferences.                </div>
              </div>

              <div className="faq-box">
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. Are there any additional charges for vehicle delivery in the Greater Metropolitan Area (GAM) or SJo International Airport?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                We are pleased to offer you the convenience of free delivery in both the Greater Metropolitan Area (GAM) and SJo International Airport. This means that you can choose to receive the vehicle at either of these locations at no additional cost. This option is designed to provide you with maximum convenience at the start of your trip, whether you are arriving from out of town or you wish to pick up your vehicle directly in the city center.                </div>
              </div>

              <div className="faq-box">
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__question ${getClassQuestion("q4")}`}
                >
                  <p>4. How many additional drivers are allowed at no additional cost?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q4")}`}
                >
                In our rental service, we provide the flexibility to allow up to two additional drivers at no additional cost. This means that more than one person can drive the vehicle during the rental period without incurring extra charges. This option can be beneficial if you plan to share the driving responsibility during your trip or if you have travel companions who also wish to take the wheel.              </div>
              </div>

              <div className="faq-box">
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__question ${getClassQuestion("q5")}`}
                >
                  <p>5. Are there any limits on the number of kilometers that can be driven during the rental period?</p>
                  <IconChevronDown />
                </div>
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__answer ${getClassAnswer("q5")}`}
                >
                You don't have to worry about restrictions on the amount of miles you can drive during the rental period. We offer unlimited mileage, which means you can travel without distance limitations, whether you are planning a short trip or a longer adventure. This option gives you total freedom to explore your destination without having to worry about additional costs based on distance traveled.              
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
