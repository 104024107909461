import React, { Component } from "react";
import CarBox from "./CarBox";
import { CAR_DATA } from "./CarData";
import { Select, MenuItem, FormControl } from '@mui/material';
import { CarContext } from './CarContext';  // Asegúrate de que la ruta es correcta según tu estructura de carpetas


class PickCar extends Component {
  static contextType = CarContext;

  constructor(props) {
    super(props);
    this.state = {
      active: "Daihatsu Terios Bego",
      selectedOption: "Daihatsu Terios Bego",
      isFocused: false
    };
  }

  handleSelectChange = (event) => {
    console.log("Updating car type to:", event.target.value);
    this.context.handleCarChange(event.target.value);
    this.setState({
        active: event.target.value,
        selectedOption: event.target.value
    });
};


  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  render() {
    const { active, selectedOption, isFocused } = this.state;

    const carOptions = [
      { value: 'Daihatsu Terios Bego', label: 'Daihatsu Terios Bego Or Similar' },
      { value: 'Hyundai Tucson GL 2016', label: 'Hyundai Tucson GL 2016 Or Similar' },
      { value: 'Hyundai Tucson GL 2014', label: 'Hyundai Tucson GL 2014 Or Similar' },
      { value: 'Hyundai Tucson GL 2012', label: 'Hyundai Tucson GL 2012 Or Similar' },
      { value: 'Hyundai Tucson GL 2010', label: 'Hyundai Tucson GL 2010 Or Similar' },
      { value: 'Sangyong Korando 2014', label: 'Sangyong Korando 2014 Or Similar' },
      { value: 'Kia Rio Hatchback 2014', label: 'Kia Rio Hatchback 2014 Or Similar' },
      { value: 'Suzuki Grand Vitara', label: 'Suzuki Grand Vitara Or Similar' },
  ];
  

    return (
      <>
        <section className="pick-section">
          <div className="container">
            <div className="pick-container">
              <div className="pick-container__title">
                <h3>Vehicle Models</h3>
                <h2>Our rental fleet</h2>
                <h1>
                  Choose from a variety of our amazing vehicles to rent for your
                  next adventure or business trip
                </h1>
              </div>
              <p style={{ fontFamily: 'Poppins', fontSize: '1.7rem' }}>Choose your car now!</p>
              <div className="pick-container__car-content">
                <FormControl style={{ width: '100%' }}>
                  <Select
                    labelId="car-label"
                    id="car-select"
                    value={selectedOption}
                    onChange={this.handleSelectChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    style={{ fontSize: '1.8rem', minWidth: '15rem', fontFamily: 'Poppins' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          fontSize: '1.7rem',
                        },
                      },
                    }}
                  >
                    {carOptions.map((car) => (
                      <MenuItem key={car.value} value={car.value} style={{ fontFamily: 'Poppins', fontSize: '1.7rem' }}>
                        {car.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {carOptions.map((car, index) => (
                  active === car.value && <CarBox key={car.value} data={CAR_DATA} carID={index} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PickCar;
