import DX7 from "../images/1.png";
import Terios from "../images/2.jpg";
import Tucson from "../images/3.png";
import Tucson12 from "../images/4.png";

import Sozuki_Grand from '../images/Suzuki_Grand_Vitara.jpg';
import Kia from '../images/Kia_Rio.jpg';
import SanYong from '../images/SangYong.jpg';
import Tucson_2016 from '../images/tucson2014.png';
import Tucson_2012 from '../images/Tucson_2012.jpg';
import Tucson_2010 from '../images/Tucson_2010.jpg';
import Tucson_2014 from '../images/Tucson_2014.jpg';

export const CAR_DATA = [
  [
    {
      name: "Daihatsu Terios Bego",
      price: "90",
      img: Terios,
      model: "Terios",
      mark: "Bego",
      year: "2016",
      doors: "4x4",
      air: "Yes",
      transmission: "Manual",
      fuel: "Gasoline",
      Persons: "5" // Agregado
    },
  ],
  [
    {
      name: "Hyundai Tucson GL",
      price: "110",
      img: Tucson_2014,
      model: "Tucson",
      mark: "Hyundai",
      year: "2016",
      doors: "4x4",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
      Persons: "5"
    },
  ],
  [
    {
      name: "Hyundai Tucson GL",
      price: "110",
      img: Tucson_2016,
      model: "Tucson",
      mark: "Hyundai",
      year: "2014",
      doors: "4x4",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Diesel",
      Persons: "5" // Agregado
    },
  ],
  [
    {
      name: "Hyundai Tucson GL",
      price: "110",
      img: Tucson_2012,
      model: "Tucson",
      mark: "Hyundai",
      year: "2012",
      doors: "4x4",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Diesel",
      Persons: "5" // Agregado
    },
  ],
  [
    {
      name: "Hyundai Tucson GL",
      price: "110",
      img: Tucson_2010,
      model: "Tucson",
      mark: "Hyundai",
      year: "2010",
      doors: "4x2",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Diesel",
      Persons: "5" // Agregado
    },
  ],
  [
    {
      name: "Sangyong Korando",
      price: "110",
      img: SanYong,
      model: "Korando",
      mark: "Sangyong",
      year: "2014",
      doors: "4x4",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Diesel",
      Persons: "5" // Agregado
    },
  ],
  [
    {
      name: "Kia Rio Hatchback",
      price: "110",
      img: Kia,
      model: "Rio",
      mark: "Kia",
      year: "2014",
      doors: "4x2",
      air: "Yes",
      transmission: "Manual",
      fuel: "Gasoline",
      Persons: "5" // Agregado
    },
  ],
  [
    {
      name: "Suzuki Grand Vitara",
      price: "110",
      img: Sozuki_Grand,
      model: "Grand Vitara",
      mark: "Suzuki",
      year: "2016",
      doors: "4x2",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
      Persons: "5" // Agregado
    },
  ]
];
