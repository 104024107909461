import Hero from "../components/Hero";
import HomeSection from "../components/HomeSection";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Download from "../components/Download";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';



function Home() {

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/71987667', '_blank'); // Abre en una nueva pestaña
  };

  return (
    <>
     <button className="btn-wsp" onClick={handleWhatsAppClick}>
        <FontAwesomeIcon icon={faWhatsapp} />
      </button>
      <HomeSection />
      <PickCar />
      <BookCar />
      <Banner />
      <Testimonials />
      <Faq />
      <PlanTrip />  
      <Footer />
    </>
  );
}

export default Home;
