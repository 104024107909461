import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTiktok, faInstagram, faWeixin, faTelegram, faPaypal } from '@fortawesome/free-brands-svg-icons';
import { IconMail, IconPhoneCall } from "@tabler/icons-react";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>CAR</span> Rental
              </li>
              <li>
                We offers a big range of vehicles for all your driving needs. We
                have the perfect car to meet your needs.
              </li>

              <li>
                <a href="tel:+16469802764">
                  <IconPhoneCall />
                  <br />
                  +1 (646) 980-2764
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                  costaricars@costaricarenting.com"
                >
                  <IconMail />
                  &nbsp; costaricars@costaricarenting.com
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Follow Us</li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=100089240476313">
                  <FontAwesomeIcon icon={faFacebook} className="icon" /> Facebook
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@costaricarsrenting?refer=creator_embed">
                  <FontAwesomeIcon icon={faTiktok} className="icon" /> Tik Tok
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/costaricarsrenting?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                  <FontAwesomeIcon icon={faInstagram} className="icon" /> Instagram
                </a>
              </li>
              <li>
                <a href="URL_DE_WECHAT">
                  <FontAwesomeIcon icon={faWeixin} className="icon" /> WeChat
                </a>
              </li>
              <li>
                <a href="https://t.me/costaricarsrenting">
                  <FontAwesomeIcon icon={faTelegram} className="icon" /> Telegram
                </a>
              </li>
              <li>
                <a href="https://www.paypal.com/@CostaRiCarsRenting">
                  <FontAwesomeIcon icon={faPaypal} className="icon" /> Paypal
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>We work 24/7 to offer the best to our customers.</li>
            </ul>

            <ul className="footer-content__2">
              <li>Subscription</li>
              <li>
                <p>Subscribe your Email address for latest news & updates.</p>
              </li>
              <li>
                <input type="email" placeholder="Enter Email Address"></input>
              </li>
              <li>
                <button className="submit-email">Submit</button>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
