import { useState } from "react";
import { IconMail, IconMailOpened, IconPhone } from "@tabler/icons-react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { IconLocation } from "@tabler/icons-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  // Estado para almacenar los datos del formulario
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: ""
  });

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/71987667', '_blank'); // Abre en una nueva pestaña
  };

  // Función para manejar cambios en los campos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Función para enviar datos del formulario a Web3Forms
  const sendDataToWeb3Forms = async () => {
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          apikey: "06814229-c6e1-4391-ad93-62920907b09f", // Tu API Key de Web3Forms
          ...formData
        })
      });
      if (!response.ok) {
        throw new Error("Error al enviar los datos.");
      }
      const responseData = await response.json();
      console.log("Datos enviados exitosamente:", responseData);
      setFormData({
        fullName: "",
        email: "",
        message: ""
      });
      // Aquí puedes hacer algo después de enviar los datos, como mostrar un mensaje de éxito
    } catch (error) {
      console.error("Hubo un error:", error);
      // Aquí puedes manejar el error de alguna manera, como mostrar un mensaje de error al usuario
    }
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    sendDataToWeb3Forms();
    // Puedes agregar más acciones aquí si es necesario, como limpiar el formulario
  };

  return (
    <>
     <button className="btn-wsp" onClick={handleWhatsAppClick}>
        <FontAwesomeIcon icon={faWhatsapp} />
      </button>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Need additional information?</h2>
              <p>
                A multifaceted professional skilled in multiple fields of
                research, development as well as a learning specialist. Over 15
                years of experience.
              </p>
              <a href="/">
                <IconPhone /> &nbsp; +1 (646) 9802764
              </a>
              <a href="/">
                <IconMail /> &nbsp; costaricars@costaricarenting.com
              </a>
              <a href="/">
                <IconLocation />
                &nbsp; San Jose, Costa Rica
              </a>
            </div>
            <div className="contact-div__form">
              <form onSubmit={handleSubmit}>
                <label>
                  Full Name <b>*</b>
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  placeholder='E.g: "Joe Shmoe"'
                  required
                ></input>

                <label>
                  Email <b>*</b>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="youremail@example.com"
                  required
                ></input>

                <label>
                  Tell us about it <b>*</b>
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Write Here.."
                  required
                ></textarea>

                <button type="submit">
                  <IconMailOpened />
                  &nbsp; Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <IconPhone width={40} height={40} />
                <h3>+1 (646) 980-2764</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
