import React, { useEffect, useState } from 'react';
import imgC1 from '../images/drone-tours.jpg';
import imgC2 from '../images/jake-marsee.jpg';
import imgC3 from '../images/tanabene.jpg';
import imgC4 from '../images/volcano.jpg';
import imgC5 from '../images/water-fall.jpg';
import imgC6 from '../images/volcan.jpg';
import imgC7 from '../images/Rio.jpg'; 


const HomeSection = () => {
  const [goUp, setGoUp] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  useEffect(() => {
    const nextDom = document.getElementById('next');
    const prevDom = document.getElementById('prev');
    const carouselDom = document.querySelector('.carousel');
    const sliderDom = carouselDom.querySelector('.list');
    const thumbnailBorderDom = document.querySelector('.carousel .thumbnail');
    const timeDom = document.querySelector('.carousel .time');

    const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');

    thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);

    let runTimeOut;
    let runNextAuto;

    const showSlider = (type) => {
      const sliderItemsDom = sliderDom.querySelectorAll('.item');
      if (type === 'next') {
        sliderDom.appendChild(sliderItemsDom[0]);
        thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
        carouselDom.classList.add('next');
      } else {
        sliderDom.prepend(sliderItemsDom[sliderItemsDom.length - 1]);
        thumbnailBorderDom.prepend(thumbnailItemsDom[thumbnailItemsDom.length - 1]);
        carouselDom.classList.add('prev');
      }
      clearTimeout(runTimeOut);
      runTimeOut = setTimeout(() => {
        carouselDom.classList.remove('next');
        carouselDom.classList.remove('prev');
      }, timeRunning);

      clearTimeout(runNextAuto);
      runNextAuto = setTimeout(() => {
        nextDom.click();
      }, timeAutoNext);
    };

    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    nextDom.onclick = () => showSlider('next');
    prevDom.onclick = () => showSlider('prev');

    return () => {
      window.removeEventListener("scroll", onPageScroll);
      clearTimeout(runTimeOut);
      clearTimeout(runNextAuto);
    };
  }, []);

  return (
    <section className="home" id="home">
      <div className="carousel">
        <div className="list">

          <div key={1} className="item">
            <img src={imgC1} alt="Costa RiCars" />
            <div className="content">
              <div className="author">In Guanacaste</div>
              <div className="title">
                <span style={{ color: 'red' }}>Costa Ri</span><span style={{ color: '#00BFFF' }}>Car's</span>
              </div>
              <div className="topic">
                <span style={{ color: '#00BFFF' }}>Renting</span>
              </div>
              <div className="des">
                From the beaches to the mountains, our car rental service takes you wherever you want to go in Costa Rica.
              </div>
            </div>
          </div>

          <div key={2} className="item">
            <img src={imgC2} alt="Costa RiCars" />
            <div className="content">
              <div className="author">In Sámara</div>
              <div className="title">
                <span style={{ color: 'red' }}>Costa Ri</span><span style={{ color: '#00BFFF' }}>Car's</span>
              </div>
              <div className="topic">
                <span style={{ color: '#00BFFF' }}>Renting</span>
              </div>
              <div className="des">
                Book now and enjoy the comfort and flexibility of having your own means of transportation in Costa Rica.
              </div>
            </div>
          </div>

          <div key={3} className="item">
            <img src={imgC3} alt="Costa RiCars" />
            <div className="content">
              <div className="author">In Puerto Limon</div>
              <div className="title">
                <span style={{ color: 'red' }}>Costa Ri</span><span style={{ color: '#00BFFF' }}>Car's</span>
              </div>
              <div className="topic">
                <span style={{ color: '#00BFFF' }}>Renting</span>
              </div>
              <div className="des">
                Your gateway to exploration: Rent a car with us and discover all that Costa Rica has to offer.
              </div>
            </div>
          </div>

          <div key={4} className="item">
            <img src={imgC7} alt="Costa RiCars" />
            <div className="content">
              <div className="author">In Sarapiquí</div>
              <div className="title">
                <span style={{ color: 'red' }}>Costa Ri</span><span style={{ color: '#00BFFF' }}>Car's</span>
              </div>
              <div className="topic">
                <span style={{ color: '#00BFFF' }}>Renting</span>
              </div>
              <div className="des">
                Discover the beauty of Costa Rica at the wheel of one of our rental cars.
              </div>
            </div>
          </div>

          
          <div key={5} className="item">
            <img src={imgC6} alt="Costa RiCars" />
            <div className="content">
              <div className="author">In Fortuna</div>
              <div className="title">
                <span style={{ color: 'red' }}>Costa Ri</span><span style={{ color: '#00BFFF' }}>Car's</span>
              </div>
              <div className="topic">
                <span style={{ color: '#00BFFF' }}>Renting</span>
              </div>
              <div className="des">
                Explore the wonders of Costa Rica from behind the wheel of our premium rental vehicles.
              </div>
            </div>
          </div>


          {/* Rest of your carousel items */}
        </div>


        <div className="thumbnail">
          {/* Your thumbnail items */}
          {carouselItems.map((item, index) => (
            <div key={index} className="item">
              <img src={item.image} alt={item.title} />
              <div className="content">
                <div className="title">{item.thumbnailTitle}</div>
                <div className="description">{item.thumbnailDescription}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="arrows">
          <button id="prev">{"<"}</button>
          <button id="next">{">"}</button>
        </div>
        <div className="time"></div>
      </div>

      {/* page up */}
      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        ^
      </div>
    </section>
  );
};

const timeRunning = 3000;
const timeAutoNext = 7000;

const carouselItems = [
  {
    image: imgC1,
    author: "In Guanacaste",
    title: "Costa RiCars",
    topic: "Renting",
    description: "From the beaches to the mountains, our car rental service takes you wherever you want to go in Costa Rica.",
    thumbnailTitle: "Liberia",
    thumbnailDescription: "Guanacaste"
  },
  {
    image: imgC2,
    author: "In Sámara",
    title: "Costa RiCars",
    topic: "Renting",
    description: "Book now and enjoy the comfort and flexibility of having your own means of transportation in Costa Rica.",
    thumbnailTitle: "Sámara",
    thumbnailDescription: "Guanacaste"
  },
  {
    image: imgC3,
    author: "In Puerto Limon",
    title: "Costa RiCars",
    topic: "Renting",
    description: "Your gateway to exploration: Rent a car with us and discover all that Costa Rica has to offer.",
    thumbnailTitle: "Puerto",
    thumbnailDescription: "Limon"
  },
  {
    image: imgC7,
    author: "In Sarapiquí",
    title: "Costa RiCars",
    topic: "Renting",
    description: "Discover the beauty of Costa Rica at the wheel of one of our rental cars.",
    thumbnailTitle: "Horquetas",
    thumbnailDescription: "Sarapiquí"
  },
  {
    image: imgC6,
    author: "In Fortuna",
    title: "Costa RiCars",
    topic: "Renting",
    description: "Explore the wonders of Costa Rica from behind the wheel of our premium rental vehicles.",
    thumbnailTitle: "Fortuna",
    thumbnailDescription: "Volcano"
  }
];


export default HomeSection;
