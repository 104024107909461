import React, { createContext, useState, useContext } from 'react';

export const CarContext = createContext();

export function useCar() {
    return useContext(CarContext);
}

export const CarProvider = ({ children }) => {
    const [carType, setCarType] = useState("");

    const handleCarChange = (newType) => {
        console.log("Car type changed to:", newType); // Verifica que esta línea se imprima con el nuevo valor.
        setCarType(newType);
    };

    return (
        <CarContext.Provider value={{ carType, handleCarChange }}>
            {children}
        </CarContext.Provider>
    );
};
